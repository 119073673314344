import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import data from "./data";
import SEO from "../../seo";
export default function PerformanceArts() {
  return (
    <Layout>
      <SEO
        title="Performance Arts | Chinmaya Vidyalaya"
        description="Students are encouraged to explore the plethora of artistic activities and creative pursuits on offer – including Indian classical music, dance and theatre performances."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="performancearts">
        <h1 className="heading">Performing Arts</h1>

        <p className="performancearts__text">
          <strong>Hues of learning</strong>
          <br />
          <br />
          Performing Arts can change the world because it can change people.
          Cocurricular activities and experiential learning equips the students
          with values, attitude and skills needed for being a global citizen.
          Music education in school is vital for the holistic development of
          students as it nurtures cognitive, emotional and social skills,
          promotes cultural understanding and contributes to overall well being.
          It fosters creativity, self confidence and emotional intelligence.
          <br />
          <br />
          Our responsibility at Chinmaya is to empower the wings of the
          students, encourage them to do it with passion. We believe that each
          child is special so we nurture their hidden talents and enhance their
          power of expression and bolster their self confidence.
          <br />
          <br />
          Participating in various Music ensemble promotes team work,
          cooperation and communication skills It is fascinating to see the
          students perform variety of musical instruments, expressing themselves
          through dance and music, thus unleashing their creativity and perform
          without inhibitions.
        </p>

        <div className="performancearts__images">
          <div className="row">
            {data.map((item) => (
              <div className="col col-4">
                <img className="performancearts__images__img" src={item}></img>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
