const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/pic1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/pic2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/pic3.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/pic4.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/pic5.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/pic6.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img1.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img2.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img3.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img4.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img5.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img6.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/performing-arts/img7.JPG",
].reverse();

export default data.reverse();
